<template>
  <div v-show="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      width="680px"
      :before-close="beforeClose"
    >
      <div class="dialog-delete" @click="beforeDelete">
        <i class="el-icon-close"></i>
      </div>
      <div class="evaluateBox">
        <div class="title" v-if="activityName">
          活动名称：{{ activityName }}
        </div>
        <div class="title" v-else>活动评价</div>
        <div class="flex bor">
          <div style="marginright: 20px">请您为培训的总体满意度打分</div>
          <rating
            :scoreNum="grade"
            :incidentClick="incidentClick"
            :grade="false"
            :maeginRight="15"
            :gradeLeft="21"
            @selectStar="selectStar"
          >
          </rating>
        </div>
        <div
          class="bor-active"
          v-if="activityList.length > 1 || questions.length > 1"
        >
          <template v-if="activityList.length > 1">
            <div class="c-top">
              <span>*</span> 请选择问卷类型<span class="c-top-l"
                >(必填，单选)</span
              >
            </div>
            <div
              v-for="itme in activityList"
              :key="itme.id"
              :class="['f-select', itme.description ? 'active' : '']"
              @click="activityInsuk(itme)"
            >
              {{ itme.paperTag }}
            </div>
          </template>
          <template>
            <div
              v-for="(obj, i) in questions"
              :key="i"
              @click="questionnaireObjInsuk(obj, i)"
            >
              <div class="c-top">
                <span>{{ obj.d }}</span> {{ obj.title }}
                <span class="c-top-l">{{ obj.text }}</span>
              </div>
              <div class="flex conet" v-if="obj.type == 'CHECKBOX'">
                <div
                  v-for="(itmea, indexs) in obj.options"
                  :key="indexs"
                  @click="checkboxIncident(itmea, indexs)"
                >
                  <div :class="['c-select', itmea.description ? 'active' : '']">
                    {{ itmea.optionValue }}
                  </div>
                </div>
              </div>
              <div class="flex conet" v-if="obj.type == 'RADIO'">
                <div
                  v-for="(itme, index) in obj.options"
                  :key="index"
                  @click="descriptionInck(itme, index, obj)"
                  :class="['f-select', itme.description ? 'active' : '']"
                >
                  {{ itme.optionValue }}
                </div>
              </div>
              <div class="flex conet" v-if="obj.type == 'TEXT'">
                <div class="textarea">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 4 }"
                    maxlength="200"
                    placeholder="请输入不超过200字的意见或者建议"
                    @blur="textIncident(obj, i)"
                    :disabled="manipulable"
                    v-model="obj.description"
                  >
                  </el-input>
                  <div class="textarea-btm">
                    {{ obj.description ? obj.description.length : 0 }} / 200
                  </div>
                </div>
              </div>
              <div v-if="obj.type == 'STAR'">
                <div class="flex conet">
                  <rating
                    :incidentClick="incidentClick"
                    :grade="false"
                    :maeginRight="15"
                    :gradeLeft="21"
                    @selectStar="starionnaireObjInsuk($event, obj, i)"
                    :scoreNum="obj.description"
                  ></rating>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          :class="['checked', isAnonymous ? 'active' : '']"
          @click="isAnonymousIncident"
        >
          匿名评价
        </div>
        <el-button
          :loading="loading"
          :class="['evaluate-btn', manipulable ? 'active' : '']"
          @click="submitIncident"
          >{{ manipulable ? "已提交" : "提交" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Utils from '@/utils';
import Vue from 'vue';
import Rating from '@/components/Rating/index';
import { Input } from 'element-ui';
Vue.use(Input);
import {
  myCourseEvaluationDetail,
  withoutMyCourseEvaluationDetail,
  activitySearchQuestionIds,
  getQuestionPaperInfoByActivityId
} from '@/api/learn';
export default {
  name: 'PopupTable',
  components: {
    Rating
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    manipulable: {
      // 评价 ，查看
      type: Boolean,
      default: false
    },
    activityId: {
      type: String,
      default: ''
    },

    activityName: {
      type: String,
      default: ''
    },
    questionPaperId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      grade: 0,
      incidentClick: true,
      tbUserAnswers: [],
      questions: [],
      activityList: [], // 活动
      activity: false,
      isAnonymous: false, // 是否匿名评价
      questionPaperIds: '', // 问卷id
      loading: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      /**
       *  @manipulable true 查看 false 评价
       * **/
      if (this.manipulable) {
        if (this.questionPaperId) {
          this.getActivitySearchQuestionIds();
        }
        this.questionPaperId
          ? this.getMyCourseEvaluationDetail(
              this.questionPaperId,
              this.activityId
            )
          : this.getWithoutMyCourseEvaluationDetail(this.activityId);
      } else {
        // 查询活动下面的所有问卷信息
        this.getActivitySearchQuestionIds();
      }
    },

    // 查询问卷信息
    getActivitySearchQuestionIds() {
      activitySearchQuestionIds({ activityId: this.activityId }).then((res) => {
        if (res.code === 4110) {
          const obj = {
            title: '抱歉，请继续学习，问卷将于最后一节课开放填写。',
            btnText: '知道了'
          };
          this.$periodPopup(obj).then(() => {
            this.incidentClick = false;
          });
          return;
        }
        if (res.data === null) return;
        if (res.data.length > 1) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['description'] = false;
          }
          if (this.manipulable) {
            res.data.forEach((val) => {
              if (val.id == this.questionPaperId) {
                val.description = true;
              }
            });
          }
          this.activityList = res.data;
        } else if (res.data.length == 1) {
          this.questionPaperIds = res.data[0].id;
          this.commGetQuestionPaperInfoByActivityId(res.data[0].id);
        } else {
          this.questionPaperIds = null;
        }
      });
    },
    commGetQuestionPaperInfoByActivityId(questionPaperId) {
      getQuestionPaperInfoByActivityId({
        questionPaperId: questionPaperId
      }).then((r) => {
        if (r.code == 200) {
          const { questions } = r.data;
          this.commType(questions);
          this.questions = questions;
        }
      });
    },
    commType(questions) {
      questions.forEach((val) => {
        if (val.isRequire) {
          val.d = '*';
          switch (val.type) {
            case 'CHECKBOX':
              val.text = '(必填，多选)';
              break;
            case 'RADIO':
              val.text = '(必填，单选)';
              break;
            case 'TEXT':
              val.text = '(必填，文本)';
              break;
            case 'STAR':
              val.text = '(必填，评分)';
              break;
            default:
              break;
          }
        } else {
          val.d = '';
          val.text = '';
        }
      });
    },
    // 点击选择问卷标题获取问卷
    activityInsuk(itme) {
      if (this.manipulable) return;
      const _this = this;
      _this.activityList.forEach((val) => {
        val.description = false;
      });
      itme.description = !itme.description;
      _this.questionPaperIds = itme.id;
      this.commGetQuestionPaperInfoByActivityId(itme.id);
      _this.activity = true;
    },
    // questionPaperId有的时候获取问卷
    getMyCourseEvaluationDetail(questionPaperId, activityId) {
      const jsonData = {
        questionPaperId: questionPaperId,
        activityId: activityId
      };
      myCourseEvaluationDetail(jsonData).then((res) => {
        if (res.code == 200) {
          const { score, questions, isAnonymous } = res.data;
          this.grade = score;
          if (questions.length > 0) {
            this.commType(questions);
            for (let i = 0; i < questions.length; i++) {
              if (
                questions[i].type == 'RADIO' ||
                questions[i].type == 'CHECKBOX'
              ) {
                var arr = questions[i].answer;
                for (let j = 0; j < questions[i].options.length; j++) {
                  if (
                    arr.indexOf(String(questions[i].options[j].optionId)) != -1
                  ) {
                    questions[i].options[j].description = true;
                  }
                }
              }
              if (questions[i].type == 'TEXT') {
                questions[i].description = questions[i].answer[0];
              }
              if (questions[i].type == 'STAR') {
                questions[i].description = parseInt(questions[i].answer[0]);
              }
            }
          }
          this.questions = questions;
          this.isAnonymous = isAnonymous;
        }
      });
    },
    // activityId获取问卷 没有试题
    getWithoutMyCourseEvaluationDetail(activityId) {
      const jsonData = {
        activityId: activityId
      };
      withoutMyCourseEvaluationDetail(jsonData).then((res) => {
        if (res.code == 200) {
          const { score, isAnonymous } = res.data;
          this.grade = score;
          this.isAnonymous = isAnonymous;
        }
      });
    },
    questionnaireObjInsuk(itme, index) {
      if (this.manipulable) return;
    },
    isAnonymousIncident() {
      if (this.manipulable) return;
      if (!this.incidentClick) {
        Utils.msgWarning(
          '抱歉，请继续学习，问卷将于最后一节课开放填写。',
          3000
        );
        return;
      }
      this.isAnonymous = !this.isAnonymous;
    },
    descriptionInck(itme, index, obj) {
      if (this.manipulable) return;
      obj.options.forEach((val) => {
        val.description = false;
      });
      itme.description = !itme.description;
    },
    beforeClose() {
      this.$emit('beforeClose');
    },
    beforeDelete() {
      this.$emit('beforeDelete');
    },
    // 选择
    starionnaireObjInsuk(e, star, index) {
      if (this.manipulable) return;
      star.description = e;
    },
    // 提交问卷
    submitIncident() {
      if (this.manipulable) return;
      if (!this.incidentClick) {
        Utils.msgWarning(
          '抱歉，请继续学习，问卷将于最后一节课开放填写。',
          3000
        );
        return;
      }
      if (this.grade <= 0) {
        Utils.msgWarning('总评分不能为空');
        return;
      }
      if (this.activityList.length > 1) {
        if (!this.activity) {
          Utils.msgWarning('请选择活动');
          return;
        }
      }
      var arrList = [];  // 选中的值
      var arrRequired = []; // 必须选中的校验
      var arrAllList = [];  // 全部的进行过滤
      if (this.questions != null) {
        for (let i = 0; i < this.questions.length; i++) {
          if (this.questions[i].isRequire) {
            arrRequired.push(this.questions[i]);
          }
          var obj = {};
          if (
            this.questions[i].type == 'CHECKBOX' ||
            this.questions[i].type == 'RADIO'
          ) {
            for (let j = 0; j < this.questions[i].options.length; j++) {
              if (this.questions[i].options[j].description) {
                obj = {
                  activityId: this.activityId,
                  type: this.questions[i].type,
                  value: this.questions[i].options[j].optionId,
                  questionId: this.questions[i].questionId
                };
                arrList.push(obj);
              } else {
                obj = {
                  activityId: this.activityId,
                  type: this.questions[i].type,
                  value: '',
                  questionId: this.questions[i].questionId
                };
              }
              arrAllList.push(obj);
            }
          }
          if (
            this.questions[i].type == 'TEXT' ||
            this.questions[i].type == 'STAR'
          ) {
            if (this.questions[i].description) {
              obj = {
                activityId: this.activityId,
                type: this.questions[i].type,
                value: this.questions[i].description,
                questionId: this.questions[i].questionId
              };
              arrList.push(obj);
            } else {
              obj = {
                activityId: this.activityId,
                type: this.questions[i].type,
                value: '',
                questionId: this.questions[i].questionId
              };
            }
            arrAllList.push(obj);
          }
        }
      }
      // 校验是否操作了必须项}
      if (arrRequired.length > 0) {
        if (arrList.length > 0) {
          // 过滤数据
          const set = arrList.map((item) => item.questionId);
          arrRequired = arrRequired.filter(
            (item) => !set.includes(item.questionId)
          );
          for (let i = 0; i < arrRequired.length; i++) {
            Utils.msgWarning(arrRequired[i]['title'] + '是必须的');
            return;
          }
        } else {
          Utils.msgWarning(
            `还有${arrRequired.length - arrList.length}个是必选(填)项`
          );
          return;
        }
      }
      // this.tbUserAnswers = arrAllList;
      this.tbUserAnswers = arrList
      const submitData = {
        activityId: parseInt(this.activityId),
        tbUserAnswers: this.tbUserAnswers,
        totalScore: parseInt(this.grade),
        questionPaperId: parseInt(this.questionPaperIds),
        isAnonymous: this.isAnonymous
      };
      this.loading = true;
      this.$emit('submitIncident', submitData);
    },
    loadingIncident() {
      this.loading = false;
    },
    /**
     * @type  评分的类型
     * @event 组件的定义参数
     * */
    selectStar(event) {
      if (this.manipulable) return;
      this.grade = event;
    },
    // 多选
    checkboxIncident(checkbox, index) {
      if (this.manipulable) return;
      checkbox.description = !checkbox.description;
    },
    textIncident(text) {
      if (this.manipulable) return;
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
/deep/.el-dialog {
  border-radius: 10px;
  height: auto;
  margin-top: 40px !important;
  overflow: hidden;
}
/deep/.el-dialog__headerbtn {
  display: none;
}
.dialog-delete {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  .el-icon-close {
    font-size: 20px;
  }
  .el-icon-close:hover {
    color: #00bd92;
  }
}
@import "~./popup.less";
.evaluateBox {
  width: 100%;
  background: @TEXT-BG-FFFFFF;
  margin: auto;
  box-sizing: border-box;
  padding: 10px 60px 30px 60px;
  .fontSize(@TEXT-SIZE-16);
  .title {
    .fontSize(@TEXT-SIZE-18);
    font-weight: bold;
    text-align: center;
  }
  .c-top {
    margin-top: 30px;
    margin-bottom: 20px;
    span {
      color: #ff5102;
    }
    .c-top-l {
      margin-left: 10px;
    }
  }
  .bor-active {
    .border-direction(bottom,1px,solid,@TEXT-BG-F4F4F4);
    padding-bottom: 30px;
  }
  .bor {
    .border-direction(bottom,1px,solid,@TEXT-BG-F4F4F4);
    padding-top: 30px;
    box-sizing: border-box;
    padding-bottom: 30px;
  }
  .grade-box {
    box-sizing: border-box;
    padding-top: 10px;
    .header-img {
      width: 80px;
      height: 80px;
      margin-right: 50px;
      margin-left: 20px;
      border-radius: 50%;
      overflow: hidden;
    }
    .text {
      margin-right: 20px;
    }
    .bottom {
      margin-bottom: 20px;
    }
  }
  .conet {
    flex-wrap: wrap;
  }
  .f-select {
    width: 250px;
    margin-bottom: 15px;
    word-wrap: break-word;
    padding-left: 29px;
    background: url("~@/assets/images/home/unselected.png") no-repeat 0px 0px;
    height: 20px;
    line-height: 18px;
    cursor: pointer;
    &.active {
      background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px 0px;
    }
  }
  .c-select {
    width: 250px;
    margin-bottom: 15px;
    word-wrap: break-word;
    padding-left: 29px;
    background: url("~@/assets/images/home/unselected-c.png") no-repeat 0px 0px;
    height: 20px;
    line-height: 18px;
    cursor: pointer;
    &.active {
      background: url("~@/assets/images/home/pitch-on-c.png") no-repeat 0px 0px;
    }
  }
  .textarea {
    width: 100%;
    position: relative;
  }
  .textarea-btm {
    position: absolute;
    bottom: 10px;
    right: 10px;
    .fontSize(@TEXT-SIZE-14);
    color: @TEXT-COLOR-888888;
  }
  .evaluate-btn {
    margin: 40px auto;
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF;
    background: @TEXT-BG-00BD92;
    cursor: pointer;
    &.active {
      background: #e6e6e6;
    }
  }

  .checked {
    height: 20px;
    box-sizing: border-box;
    padding-left: 25px;
    background: url("~@/assets/images/home/pitch.png") no-repeat 0px 0px;
    line-height: 18px;
    color: #797675;
    cursor: pointer;
    margin-top: 20px;
    &.active {
      background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px 0px;
    }
  }
  /deep/.el-button {
    &:active,
    &:focus,
    &:hover {
      border: transparent !important;
    }
  }
}
/deep/.el-textarea__inner {
  border: 1px solid #e6e6e6;
  .fontSize(@TEXT-SIZE-14) !important;
  color: @TEXT-COLOR-888888 !important;
}
/deep/.el-textarea__inner:focus {
  border: 1px solid @TEXT-BG-00BD92;
}
</style>