<template>
  <div class="rating">
    <div class="star-evaluation flex conet">
      <span
        v-for="(itemClass, index) in itemClasses"
        :key="index"
        :class="['s-rating', itemClass]"
        :style="{ marginRight: maeginRight + 'px' }"
        @click="selectStar(index, $event)"
      >
      </span>
      <span
        class="s-rating-text"
        v-if="grade"
        :style="{ marginLeft: marginLeft + 'px' }"
      >
        {{ scoreNum }} 分
      </span>
    </div>
  </div>
</template>
<script>
const LENGTH = 5;
const CLS_ON = 'icon-grade-full'; // 全星
const CLS_OFF = 'icon-grade-empty'; // 空星
export default {
  name: 'Rating',
  props: {
    scoreNum: {
      type: Number,
      default: 0
    },
    incidentClick: {
      type: Boolean,
      default: false
    },
    maeginRight: {
      type: Number,
      default: 0
    },
    grade: {
      type: Boolean,
      default: false
    },
    marginLeft: {
      type: Number,
      default: 0
    },
    gradeLeft: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      score: ''
    };
  },
  computed: {
    itemClasses() {
      const result = []; // 数组,用来遍历输出星星
      const scoreNum = Math.floor(this.scoreNum * 2) / 2; // 计算所有星星的数量
      const integer = Math.floor(scoreNum); // 整数星星判断
      for (let i = 0; i < integer; i++) {
        // 整数星星使用on
        result.push(CLS_ON); // 一个整数星星就push一个CLS_ON到数组
      }
      while (result.length < LENGTH) {
        // 余下的用无星星补全,使用off
        result.push(CLS_OFF); // 类似
      }
      return result;
    }
  },
  methods: {
    selectStar(index, event) {
      if (!this.incidentClick) return;
      this.score = index + 1;
      this.$emit('selectStar', this.score);
    }
  }
};
</script>