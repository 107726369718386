<template>
  <div class="tab-box">
    <ul>
      <li
        v-for="(itme, index) in detaList"
        :key="index"
        :class="detaIndex == index ? 'active' : ''"
        @click="detaIncident(index)"
      >
        {{ itme }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'TabBox',
  props: {
    detaList: {
      type: Array,
      default: []
    },
    detaIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    detaIncident(index) {
      this.$emit('detaIncident', index);
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.tab-box {
  box-sizing: border-box;
  ul {
    width: 100%;
    li {
      display: inline-block;
      width: 148px;
      height: 40px;
      line-height: 38px;
      text-align: center;
      border-radius: 20px;
      border: 1px solid @TEXT-BG-E6E6E6;
      margin-right: 20px;
      cursor: pointer;
      color: @TEXT-COLOR-888888;
      .fontSize(@TEXT-SIZE-16);
      &.active {
        background: @TEXT-BG-00BD92;
        border: 1px solid @TEXT-BG-00BD92;
        color: @TEXT-COLOR-FFFFFF;
        &:hover {
          background: @TEXT-BG-00CC9D;
          border: 1px solid @TEXT-BG-00CC9D;
        }
      }
    }
    li:hover {
      background: @TEXT-BG-E6E6E6;
    }
  }
}
</style>