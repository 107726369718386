import { ajax } from './config';
/**
 * 用户信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const myCourse = (params) => ajax('/v1/myCourse', 'GET', { params, useToken: true })
/**
 * 提交课程评价
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const postMyCourseEvaluation = (params) => ajax('/v1/myCourse/evaluation', 'POST', { params, useToken: true })

/**
 * 查看报名信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const examineMyCourseRegisterInfo = (params) => ajax(`/v1/myCourse/registerInfo/${params.activityId}`, 'GET', { useToken: true })

/**
 * 发送报名详细信息到邮箱
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const myCourseSendEmail = (params) => ajax(`/v1/myCourse/sendEmail`, 'GET', { params, useToken: true })

/**
 * 通过活动id查询问卷详情
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const getQuestionPaperInfoByActivityId = (params) => ajax(`/v1/mini/activityDetail/getQuestionPaperInfoByActivityId`, 'GET', { params, useToken: true })

/**
 * 查看问卷评价
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const myCourseEvaluationDetail = (params) => ajax(`/v1/myCourse/evaluation/detail/${params.questionPaperId}/${params.activityId}`, 'GET', { useToken: true })
/**
 * 查看问卷无问卷的
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const withoutMyCourseEvaluationDetail = (params) => ajax(`/v1/myCourse/evaluation/detail/${params.activityId}`, 'GET', { useToken: true })

/**
 * 查询活动下面的所有问卷信息
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const activitySearchQuestionIds = (params) => ajax(`/v1/admin/activity/searchQuestionIds/${params.activityId}`, 'GET', { useToken: true })

// /api/v1/myCourse/deleteRegisterInfo/{activityId}
/**
 * 我的课程-->删除
 * @param {Object} params .
 * @returns {AxiosPromise<any>} .
 */
export const deleteRegisterInfo = (params) => ajax(`/v1/myCourse/deleteRegisterInfo/${params.activityId}`, 'PUT', { useToken: true })